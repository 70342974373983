
  import { mapGetters } from 'vuex';

  export default {
    props: {
      countdownData: {
        type: Object,
      },
    },
    components: {
      Flipcountdown: () => import('vue2-flip-countdown'),
    },
    computed: {
      ...mapGetters({
        isDatePassed: 'countdown/isDatePassed',
        isLoading: 'countdown/isLoading',
      }),
    },
  };
